import { drawPrintPreviewCounter } from "arris_dist";
import { drawPrintSink } from "arris_dist";
import { drawPrintCooktop } from "arris_dist";
import { getOptimalZoomMultiple } from "arris_dist";
import { getTopLeftOfCounters } from "arris_dist";
import { Counter } from "arris_dist";
import { IShopCounter } from "arris_dist";
import { Vector2 } from "arris_dist";
import { View } from "@react-pdf/renderer";
import { Canvas } from "@react-pdf/renderer";

interface Props {
  counters: Counter[] | IShopCounter[]
}

export default function QuotePrintPreviewAllCounters({ counters }: Props) {
  const paintCanvas = (painter: any, availableWidth: number, availableHeight: number) => {
    const zoom: number = getOptimalZoomMultiple(counters, { X: 475, Y: 475 });
    const center: Vector2 = getTopLeftOfCounters(counters, zoom);

    painter.fontSize(Math.round(8));

    const offset: Vector2 = { X: Math.round(0 - center.X), Y: Math.round(0 - center.Y) };

    for (let i = 0; i < counters.length; i++) {
      drawPrintPreviewCounter(
        counters[i]?.corners ?? [],
        counters[i]?.points ?? [],
        painter,
        offset,
        zoom
      );

      drawPrintCooktop(painter, counters[i], offset, zoom);
      drawPrintSink(painter, counters[i], offset, zoom);
      painter.stroke("#666666");
    }

    return null;
  }

  return (
    <View style={{ marginTop: "5px", flexBasis: "50%", height: "525px", width: "750px" }} wrap={false}>
      <Canvas
        style={{ height: "525px", width: "750px" }}
        paint={paintCanvas} />
    </View>
  );
}
