import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { Action } from "api/action_types/signature";
import { Action as OrderAction } from "api/action_types/order";
import { Action as QuoteAction } from "api/action_types/quote";
import { Action as SelfOrderAction } from "api/action_types/self_order";
import { Action as SketchAction } from "api/action_types/sketch";
import { ActionType } from "api/action_types/signature";
import { ActionType as QuoteActionType } from "api/action_types/quote";
import { ActionType as SketchActionType } from "api/action_types/sketch";
import { url_prefix } from "settings";
import { quote_signature_url } from "../../endpoint";

export const getQuoteSignature = (signature_key: string) => {
  return async (dispatch: Dispatch<Action | OrderAction | QuoteAction | SelfOrderAction | SketchAction>) => {
    dispatch({
      type: ActionType.SIGNATURE_LOADING
    });

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }

    const url = `${url_prefix}${quote_signature_url}/0/?signature_key=${signature_key}`;

    try {
      const { data } = await axios.get(url, config);

      if (data.id) {
        dispatch({
          type: ActionType.GET_SIGNATURE_SUCCESS,
          signature: data.signature
        });

        dispatch({
          type: QuoteActionType.GET_QUOTE_SUCCESS,
          quote: data.quote
        });

        dispatch({
          type: SketchActionType.GET_COUNTERS_SUCCESS,
          counters: data.counters
        });

        dispatch({
          type: SketchActionType.GET_AREAS_SUCCESS,
          areas: data.areas
        });

        dispatch({
          type: QuoteActionType.GET_QUOTE_VENDOR_SUCCESS,
          vendor: data.vendor
        });
      }
      else {
        dispatch({
          type: ActionType.ALREADY_SIGNED
        });
      }
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.SIGNATURE_ERROR,
          error: err.message
        });
      }
    }
  }
}
