import { useState } from "react";
import { useEffect } from "react";
import { useAppSelector } from "hooks/api";
import Box from "@mui/material/Box";
import PrintQuoteSummary from "./quote_summary";
import PrintLineItems from "./line_items";
import QuotePrintPreviewAllCounters from "./all_counters";
import PrintHeader from "../shared/header";
import PrintSummaryHeaders from "../shared/summary_headers";
import PrintTerms from "../shared/terms";
import PrintSignature from "../shared/signature";
import { PDFViewer } from "@react-pdf/renderer";
import { Page } from "@react-pdf/renderer";
import { Document } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { Image } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import PowerByLogo from "static/powered_by_logo.png";
import { primary_color } from "styles/theme";

export default function PrintPreview() {
  const [logo, setLogo] = useState<string | null>(null);
  const [logo_read, setLogoRead] = useState<boolean>(false);
  const { quote } = useAppSelector((state) => state.quote);
  const { counters } = useAppSelector((state) => state.sketch);
  const { areas } = useAppSelector((state) => state.quote);
  const { vendor } = useAppSelector((state) => state.quote);

  useEffect(() => {
    if (vendor.logo) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          setLogo(reader.result as string);
          setLogoRead(true);
        }
        reader.readAsDataURL(xhr.response);
      };
      if (typeof (vendor.logo) === "string") {
        xhr.open("GET", vendor.logo);
        xhr.responseType = "blob";
        xhr.send();
      }
    }
    else {
      setLogoRead(true);
    }
  }, [vendor.logo]);

  Font.register({
    family: "Montserrat",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf",
      },
      {
        src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf",
        fontWeight: "bold"
      }
    ]
  });

  const styles = StyleSheet.create({
    page: {
      paddingLeft: "50px",
      paddingRight: "50px",
      paddingTop: "50px",
      paddingBottom: "70px"
    },
    footer: {
      backgroundColor: primary_color,
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      height: "25px",
      paddingBottom: "2px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    footer_image: {
      height: "20px",
      width: "100px"
    }
  });

  //TODO add back logo_read to loading values
  //  <Box position="absolute" paddingLeft="50px" paddingRight="50px" paddingTop="50px" </ Box>
  //   <LoadingPage loading={!quote?.id || !user?.id || !logo_read || !counters_loaded || !areas_loaded}>
  //    <Box display="flex" justifyContent="center">
  //     <PDFViewer width="795px" height="1123px">
  //      <QuotePrintPreviewBody areas={areas} counters={counters} user={user} quote={quote} labels={labels} logo={logo} />
  //j   </PDFViewer>
  //</Box>
  //</LoadingPage>
  // </motion.div>
  return (
    <Box display="flex" justifyContent="center" sx={{ height: "100vh", width: "100%", paddingTop: "80px" }}>
      {
        quote && quote.id && vendor && vendor.id ?
          <PDFViewer width="795px">
            <Document>
              <Page size="A4" style={styles.page}>
                <PrintHeader
                  title="ORDER ESTIMATE"
                  number={quote && quote.estimate_number ? quote.estimate_number : ""}
                  logo_url={logo ? logo : ""}
                  seller_name={vendor.business_name ? vendor.business_name : ""}
                  seller_address={vendor.address ? vendor.address : ""}
                  seller_city={vendor.city ? vendor.city : ""}
                  seller_province={vendor.province ? vendor.province : ""}
                  seller_postal_code={vendor.postal_code ? vendor.postal_code : ""}
                  buyer_name={
                    quote && quote.customer_data ?
                      `${quote.customer_data.first_name} ${quote.customer_data.last_name}` :
                      ""
                  }
                  buyer_address={quote?.customer_data?.address ?? ""}
                  buyer_city={quote?.customer_data?.city ?? ""}
                  buyer_province={quote?.customer_data?.province ?? ""}
                  buyer_postal_code={quote?.customer_data?.postal_code ?? ""}
                  date={quote?.date_created ?? ""} />
                <PrintSummaryHeaders />
                <PrintLineItems areas={areas} />
                <PrintQuoteSummary quote={quote} />
                <QuotePrintPreviewAllCounters counters={counters} />
                <PrintTerms payment_terms={vendor.payment_terms ? vendor.payment_terms : ""} />
                <PrintSignature />
                <View style={styles.footer} fixed>
                  <Image src={PowerByLogo} style={styles.footer_image} />
                </View>
              </Page>
            </Document>
          </PDFViewer> :
          null
      }
    </Box>
  );
}

/*export default function QuotePrintPreviewBody({ areas, counters, labels, logo, quote, user }: Props) {
  Font.register({
    family: "Montserrat",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf",
      },
      {
        src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf",
        fontWeight: "bold"
      }
    ]
  });

  const styles = StyleSheet.create({
    page: {
      paddingLeft: "40px",
      paddingRight: "40px",
      paddingTop: "40px",
      paddingBottom: "40px"
    },
    title: {
      fontWeight: "bold",
      fontSize: 14
    },
    body2: {
      fontSize: 14
    },
    logo: {
      height: "40px",
      width: "80px",
      objectFit: "contain",
      objectPosition: "left"
    },
    row: {
      marginTop: "25px",
      display: "flex",
      flexDirection: "row"
    },
    font: {
      fontFamily: "Montserrat"
    },
    footer_image: {
      position: "absolute",
      right: 10,
      bottom: 5,
      height: "20px",
      width: "20px"
    }
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <POPrintHeader
          title="ORDER ESTIMATE"
          number={quote?.estimate_number ?? ""}
          logo_url={logo ? logo : ""}
          vendor_name={`${quote?.customer_data?.first_name ?? ""} ${quote?.customer_data?.last_name ?? ""}`}
          vendor_address={quote?.customer_data?.address ?? ""}
          vendor_city={quote?.customer_data?.city ?? ""}
          vendor_province={quote?.customer_data?.province ?? ""}
          vendor_postal_code={quote?.customer_data?.postal_code ?? ""}
          vendor_phone_number={quote?.customer_data?.phone_number ?? ""}
          vendor_email={quote?.customer_data?.email ?? ""}
          bill_to_name={user?.business_name ?? ""}
          bill_to_address={user?.address ?? ""}
          bill_to_city={user?.city ?? ""}
          bill_to_province={user?.province ?? ""}
          bill_to_postal_code={user?.postal_code ?? ""}
          bill_to_phone_number={user?.phone_number ?? ""}
          bill_to_email={user?.email ?? ""}
          job_site_name={quote?.fabrication_job_data?.name ?? ""}
          job_site_address={quote?.fabrication_job_data?.address ?? ""}
          job_site_city={quote?.fabrication_job_data?.city ?? ""}
          job_site_province={quote?.fabrication_job_data?.province ?? ""}
          job_site_postal_code={quote?.fabrication_job_data?.postal_code ?? ""}
          job_site_phone_number={quote?.fabrication_job_data?.phone_number ?? ""}
          job_site_email={quote?.fabrication_job_data?.email ?? ""}
          date={quote?.date_created ?? ""} />
        <PrintSummaryHeaders />
        <PrintLineItems areas={areas ?? []} />
        <PrintSummary
          total={quote?.total ?? 0}
          tax_name={quote?.tax_name ?? "Tax"}
          tax={quote?.tax ?? 0}
          second_tax_name={quote?.second_tax_name ?? "Tax2"}
          second_tax={quote?.second_tax ?? 0}
          discount={quote?.discount ?? 0} />
        {
          containsColorOption(areas) ?
            <PrintColorOptions areas={areas} /> :
            null
        }
        <PrintTerms payment_terms={user?.payment_terms ?? ""} />
        <QuotePrintAreas counters={counters} areas={areas} labels={labels} />
        <PrintSignature signature={null} name={null} date={null} />
        <Image src={Logo} style={styles.footer_image} fixed />
      </Page>
    </Document>
  );
}*/
