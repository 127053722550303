import { IShopSlab } from "arris_dist";

export enum ActionType {
  GET_SHOP_SLABS = "GET_SHOP_SLABS"
}

interface IGetShopSlab {
  type: ActionType.GET_SHOP_SLABS,
  payload: IShopSlab
}

export type Action = IGetShopSlab;
