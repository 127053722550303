import { IShopCounter } from "arris_dist";

export enum ActionType {
  GET_SHOP_COUNTERS = "GET_SHOP_COUNTERS",
}

interface IGetShopCountersSuccess {
  type: ActionType.GET_SHOP_COUNTERS,
  counters: IShopCounter[]
}

export type Action = IGetShopCountersSuccess;
