/// HOOKS ///
import { useEffect } from "react";
import { useAppDispatch } from "hooks/api";
import { useAppSelector } from "hooks/api";
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { getOrderSignature } from "api/actions/signature";
/// COMPONENTS ///
import Box from "@mui/material/Box";
import DrawSignature from "./draw";
import PrintCustomerOrder from "./print/order/customer_order";
import Typography from "@mui/material/Typography";

export default function SignOrder() {
  const dispatch = useAppDispatch();
  const { key } = useParams();
  const { already_signed } = useAppSelector((state) => state.signature);

  useEffect(() => {
    if (key) {
      dispatch(getOrderSignature(key));
    }
  }, [dispatch, key]);

  return (
    <Box sx={{
      paddingLeft: {
        "md": 15,
        "xs": 1
      },
      paddingRight: {
        "md": 15,
        "xs": 1
      },
      paddingTop: {
        "md": 5,
        "xs": 1
      },
      paddingBottom: {
        "md": 5,
        "xs": 1
      }
    }}>
      {
        !already_signed ?
          <DrawSignature /> :
          <Box sx={{
            width: {
              "md": "795px",
              "sm": "500px",
              "xs": "350px"
            },
            marginLeft: "auto", marginRight: "auto"
          }}>
            <Typography variant="h5">
              Thank you for confirming your order. Please contact your countertop dealer directly for any further information.
            </Typography>
          </Box>
      }
      <PrintCustomerOrder />
    </Box>
  )
}
