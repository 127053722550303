import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { Action } from "api/action_types/signature";
import { ActionType } from "api/action_types/signature";
import { url_prefix } from "settings";
import { signature_url } from "../endpoint";

export const updateSignature = (signature: string, signature_key: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SIGNATURE_LOADING
    });

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }

    let url = `${url_prefix}${signature_url}/0/?signature_key=${signature_key}`;

    try {
      await axios.patch(url, { "signature": signature }, config);

      dispatch({
        type: ActionType.UPDATE_SIGNATURE_SUCCESS
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.SIGNATURE_ERROR,
          error: err.message
        });
      }
    }
  }
}
