import { IShopQuote } from "arris_dist";
import { User } from "arris_dist";

export enum ActionType {
  SHOP_QUOTE_PENDING = "SHOP_QUOTE_PENDING",
  SHOP_QUOTE_FAIL = "SHOP_QUOTE_FAIL",
  GET_SHOP_QUOTE_SUCCESS = "GET_SHOP_QUOTE_SUCCESS",
  GET_SHOP_QUOTE_VENDOR = "GET_SHOP_QUOTE_VENDOR"
}

interface IQuotePending {
  type: ActionType.SHOP_QUOTE_PENDING
}

interface IQuoteFail {
  type: ActionType.SHOP_QUOTE_FAIL,
  payload: string
}

interface IGetQuoteSuccess {
  type: ActionType.GET_SHOP_QUOTE_SUCCESS,
  quote: IShopQuote
}

interface IGetQuoteVendor {
  type: ActionType.GET_SHOP_QUOTE_VENDOR,
  vendor: User
}

export type Action = IQuotePending | IQuoteFail | IGetQuoteSuccess | IGetQuoteVendor;
