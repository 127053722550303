import type { Action as AreaAction } from "./area";
import type { Action as CounterAction } from "./counter";
import type { Action as QuoteAction } from "./quote";
import type { Action as SlabAction } from "./slab";

import { ActionType as AreaActionType } from "./area";
import { ActionType as CounterActionType } from "./counter";
import { ActionType as QuoteActionType } from "./quote";
import { ActionType as SlabActionType } from "./slab";

export const ActionType = {
  ...AreaActionType,
  ...CounterActionType,
  ...QuoteActionType,
  ...SlabActionType
}

export type Action =
  AreaAction |
  CounterAction |
  QuoteAction |
  SlabAction;

