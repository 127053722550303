import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { Action } from "api/action_types/signature";
import { Action as OrderAction } from "api/action_types/order";
import { Action as QuoteAction } from "api/action_types/quote";
import { Action as SelfOrderAction } from "api/action_types/self_order";
import { Action as SketchAction } from "api/action_types/sketch";
import { ActionType } from "api/action_types/signature";
import { ActionType as OrderActionType } from "api/action_types/order";
import { url_prefix } from "settings";
import { order_signature_url } from "../../endpoint";

export const getOrderSignature = (signature_key: string) => {
  return async (dispatch: Dispatch<Action | OrderAction | QuoteAction | SelfOrderAction | SketchAction>) => {
    dispatch({
      type: ActionType.SIGNATURE_LOADING
    });

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }

    const url = `${url_prefix}${order_signature_url}/0/?signature_key=${signature_key}`;

    try {
      const { data } = await axios.get(url, config);

      if (data.id) {
        dispatch({
          type: ActionType.GET_SIGNATURE_SUCCESS,
          signature: data.signature
        });

        dispatch({
          type: OrderActionType.GET_ORDER_SUCCESS,
          order: data.order
        });

        dispatch({
          type: OrderActionType.GET_ORDER_COUNTERS_SUCCESS,
          order_counters: data.counters
        });

        dispatch({
          type: OrderActionType.GET_ORDER_AREAS_SUCCESS,
          order_areas: data.areas
        });

        dispatch({
          type: OrderActionType.GET_ORDER_SLABS_SUCCESS,
          order_slabs: data.slabs
        });

        dispatch({
          type: OrderActionType.GET_ORDER_VENDOR_SUCCESS,
          vendor: data.vendor
        });
      }
      else {
        dispatch({
          type: ActionType.ALREADY_SIGNED
        });
      }
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.SIGNATURE_ERROR,
          error: err.message
        });
      }
    }
  }
}
