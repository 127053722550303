import { IShopArea } from "arris_dist";

export enum ActionType {
  GET_SHOP_AREAS = "GET_SHOP_AREAS",
}

interface IGetShopAreasSuccess {
  type: ActionType.GET_SHOP_AREAS,
  areas: IShopArea[]
}

export type Action = IGetShopAreasSuccess;
