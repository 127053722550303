import { combineReducers } from "redux";
import { authenticationReducer } from "./authentication";
import { materialReducer } from "./material";
import { orderReducer } from "./order";
import { quoteReducer } from "./quote";
import { regionReducer } from "./region";
import { selfOrderReducer } from "./self_order";
import { shopQuoteReducer } from "./shop_quote";
import { signatureReducer } from "./signature";
import { sketchReducer } from "./sketch";
import { uiReducer } from "./ui";

const reducers = combineReducers({
  authentication: authenticationReducer,
  material: materialReducer,
  order: orderReducer,
  quote: quoteReducer,
  region: regionReducer,
  self_order: selfOrderReducer,
  shop_quote: shopQuoteReducer,
  signature: signatureReducer,
  sketch: sketchReducer,
  ui: uiReducer
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
