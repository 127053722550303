import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { Action } from "api/action_types/signature";
import { Action as OrderAction } from "api/action_types/order";
import { Action as QuoteAction } from "api/action_types/shop_quote";
import { ActionType } from "api/action_types/signature";
import { ActionType as QuoteActionType } from "api/action_types/shop_quote";
import { url_prefix } from "settings";
import { shop_quote_signature_url } from "../../endpoint";

export const getShopQuoteSignature = (signature_key: string) => {
  return async (dispatch: Dispatch<Action | OrderAction | QuoteAction>) => {
    dispatch({
      type: ActionType.SIGNATURE_LOADING
    });

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }

    const url = `${url_prefix}${shop_quote_signature_url}/0/?signature_key=${signature_key}`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_SIGNATURE_SUCCESS,
        signature: data.signature
      });

      if (data.quote) {
        dispatch({
          type: QuoteActionType.GET_SHOP_QUOTE_SUCCESS,
          quote: data.quote
        });

        dispatch({
          type: QuoteActionType.GET_SHOP_COUNTERS,
          counters: data.counters
        });

        dispatch({
          type: QuoteActionType.GET_SHOP_AREAS,
          areas: data.areas
        });

        dispatch({
          type: QuoteActionType.GET_SHOP_QUOTE_VENDOR,
          vendor: data.vendor
        });
      }
      else {
        dispatch({
          type: ActionType.ALREADY_SIGNED
        });
      }
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.SIGNATURE_ERROR,
          error: err.message
        });
      }
    }
  }
}
