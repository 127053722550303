/// TYPES ///
import { IShopArea } from "arris_dist";
import { IShopCounter } from "arris_dist";
import { IShopCutShape } from "arris_dist";
import { IShopLabel } from "arris_dist";
import { IShopQuote } from "arris_dist";
import { User } from "arris_dist";
/// ACTIONS ///
import { Action } from "api/action_types/shop_quote";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";

interface State {
  quote: IShopQuote,
  quotes: IShopQuote[],
  areas: IShopArea[],
  cut_shapes: IShopCutShape[],
  counters: IShopCounter[],
  labels: IShopLabel[],
  vendor: User,
  counters_loaded: boolean,
  shapes_loaded: boolean,
  quote_loaded: boolean,
  areas_loaded: boolean,
  updating: boolean,
  error: string | null
}

const initialState = {
  quote: {},
  quotes: [],
  areas: [],
  cut_shapes: [],
  counters: [],
  labels: [],
  vendor: {},
  counters_loaded: false,
  shapes_loaded: false,
  quote_loaded: false,
  areas_loaded: false,
  updating: false,
  error: null
}

export const shopQuoteReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.GET_SHOP_QUOTE_SUCCESS:
      return {
        ...state,
        quote: action.quote,
        quote_loaded: true,
        error: ""
      }
    case ActionType.GET_SHOP_AREAS:
      return {
        ...state,
        areas: action.areas,
        areas_loaded: true
      }
    case ActionType.GET_SHOP_COUNTERS:
      return {
        ...state,
        counters: action.counters,
        counters_loaded: true
      }
    case ActionType.GET_SHOP_QUOTE_VENDOR:
      return {
        ...state,
        vendor: action.vendor
      }
    default:
      return state;
  }
}
