import { useState } from "react";
import { useEffect } from "react";
import { useAppSelector } from "hooks/api";
import Box from "@mui/material/Box";
import PrintQuoteSummary from "./quote_summary";
import PrintLineItems from "./line_items";
import QuotePrintPreviewAllCounters from "./all_counters";
import PrintHeader from "../shared/header";
import PrintSummaryHeaders from "../shared/summary_headers";
import PrintTerms from "../shared/terms";
import PrintSignature from "../shared/signature";
import { PDFViewer } from "@react-pdf/renderer";
import { Page } from "@react-pdf/renderer";
import { Document } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { Image } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import PowerByLogo from "static/powered_by_logo.png";
import { primary_color } from "styles/theme";

export default function ShopPrintPreview() {
  const [logo, setLogo] = useState<string | null>(null);
  const [logo_read, setLogoRead] = useState<boolean>(false);
  const { quote } = useAppSelector((state) => state.shop_quote);
  const { counters } = useAppSelector((state) => state.shop_quote);
  const { areas } = useAppSelector((state) => state.shop_quote);
  const { vendor } = useAppSelector((state) => state.shop_quote);

  useEffect(() => {
    if (vendor.logo) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          setLogo(reader.result as string);
          setLogoRead(true);
        }
        reader.readAsDataURL(xhr.response);
      };
      if (typeof (vendor.logo) === "string") {
        xhr.open("GET", vendor.logo);
        xhr.responseType = "blob";
        xhr.send();
      }
    }
    else {
      setLogoRead(true);
    }
  }, [vendor.logo]);

  Font.register({
    family: "Montserrat",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf",
      },
      {
        src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf",
        fontWeight: "bold"
      }
    ]
  });

  const styles = StyleSheet.create({
    page: {
      paddingLeft: "50px",
      paddingRight: "50px",
      paddingTop: "50px",
      paddingBottom: "70px"
    },
    footer: {
      backgroundColor: primary_color,
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      height: "25px",
      paddingBottom: "2px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    footer_image: {
      height: "20px",
      width: "100px"
    }
  });

  //TODO add back logo_read to loading values

  return (
    <Box display="flex" justifyContent="center" sx={{ height: "100vh", width: "100%", paddingTop: "80px" }}>
      {
        quote && quote.id && vendor && vendor.id ?
          <PDFViewer width="795px">
            <Document>
              <Page size="A4" style={styles.page}>
                <PrintHeader
                  title="ORDER ESTIMATE"
                  number={quote && quote.estimate_number ? quote.estimate_number : ""}
                  logo_url={logo ? logo : ""}
                  seller_name={vendor.business_name ? vendor.business_name : ""}
                  seller_address={vendor.address ? vendor.address : ""}
                  seller_city={vendor.city ? vendor.city : ""}
                  seller_province={vendor.province ? vendor.province : ""}
                  seller_postal_code={vendor.postal_code ? vendor.postal_code : ""}
                  buyer_name={
                    quote && quote.customer_data ?
                      `${quote.customer_data.first_name} ${quote.customer_data.last_name}` :
                      ""
                  }
                  buyer_address={quote?.customer_data?.address ?? ""}
                  buyer_city={quote?.customer_data?.city ?? ""}
                  buyer_province={quote?.customer_data?.province ?? ""}
                  buyer_postal_code={quote?.customer_data?.postal_code ?? ""}
                  date={quote?.date_created ?? ""} />
                <PrintSummaryHeaders />
                <PrintLineItems areas={areas} />
                <PrintQuoteSummary quote={quote} />
                <QuotePrintPreviewAllCounters counters={counters} />
                <PrintTerms payment_terms={vendor.payment_terms ? vendor.payment_terms : ""} />
                <PrintSignature />
                <View style={styles.footer} fixed>
                  <Image src={PowerByLogo} style={styles.footer_image} />
                </View>
              </Page>
            </Document>
          </PDFViewer> :
          null
      }
    </Box>
  );
}
